<template>
  <!-- Login -->
  <b-container fluid>
    <!-- Authenticating Code -->
    <b-row no-gutters style="min-height:80vh;" v-if="!$route.params.code">
      <b-col class="text-center my-auto">
        <div class="text-center">
          <b-spinner key="dark" type="grow"></b-spinner>
          <h4 class="font-weight-bold mt-3">Scan Code to Begin</h4>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Login",
  methods: {
    authenticate: function() {
      if (this.$route.params.code) {
        this.$store.commit("setLoading");
        this.$store.dispatch("getVendor").then((response) => {
          console.log(response);
          this.$store
            .dispatch("login", this.$route.params.code)
            .then((response) => {
              console.log(response);
              this.$store.dispatch("getOrder").then((response) => {
                console.log(response);
                this.$store.dispatch("connect").then((response) => {
                  console.log(response);
                  this.$store.dispatch("getItems");
                });
              });
              this.$store.commit("unsetLoading");
              this.$router.push("/");
            })
            .catch((error) => {
              console.log(error);
              this.$router.push({ Name: "InvalidCredentials" });
            });
        });
      }
    },
  },
  mounted: function() {
    this.authenticate();
  },
};
</script>
